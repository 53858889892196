import { Helmet } from "react-helmet"
import {
    Box,
    ListItem,
    OrderedList,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Thead,
    Th,
    Tr,
    UnorderedList,
} from "@chakra-ui/react"

export const PrivacyPolicyPage = () => {
    const titleFontSize = 25;
    const subtitleFontSize = 20;
    const fontSize = 15;

    const textColor = "black";

    const boxPaddingTop = 7;
    const paddingTop = 2;
    const paddingHorizontal = "5%";
    

    return (
        <>
            <Helmet>
                <title>Surf Privacy Policy</title>
                <meta name="description" content="The Searchable Dating App." />
            </Helmet>

            <Box paddingLeft={paddingHorizontal} paddingRight={paddingHorizontal}>
                <Text fontSize={30} textColor={textColor} fontWeight="bold">
                    Surf Privacy Policy
                </Text>
                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                    Surf, Inc. (&ldquo;Surf&rdquo;) has prepared this Privacy Policy (&ldquo;Policy&rdquo;) to explain the information we collect when you use our mobile application (the &ldquo;App&rdquo;), how we use that information, and your choices about that information.
                </Text>
            </Box>
            
            <Box paddingLeft={paddingHorizontal} paddingRight={paddingHorizontal} paddingTop={boxPaddingTop}>
                <Text fontSize={titleFontSize} textColor={textColor} fontWeight="bold">
                    1. Information We Collect
                </Text>
                <Text fontSize={subtitleFontSize} paddingTop={paddingTop} textColor={textColor} fontWeight="bold">
                    Registration Information:
                </Text>
                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                    Upon downloading the App and creating an account, we gather specific details about you, such as: 
                </Text>
                <UnorderedList paddingTop={paddingTop}>
                    <ListItem>Name</ListItem>
                    <ListItem>Date of birth</ListItem>
                    <ListItem>Email address</ListItem>
                    <ListItem>Phone number</ListItem>
                    <ListItem>Height</ListItem>
                    <ListItem>Gender identity</ListItem>
                    <ListItem>Sexual orientation</ListItem>
                    <ListItem>City of residence</ListItem>
                    <ListItem>Hometown</ListItem>
                    <ListItem>Education</ListItem>
                    <ListItem>Profession</ListItem>
                    <ListItem>Interests</ListItem>
                    <ListItem>Exercise</ListItem>
                    <ListItem>Ethnicity</ListItem>
                    <ListItem>Religion</ListItem>
                    <ListItem>Photos</ListItem>
                </UnorderedList>
                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                    After signing up, you have the freedom to view and modify most of this data anytime inside the App. Certain data, such as name, date of birth, email address, and phone number, cannot be modified directly inside the App. To modify this data, please contact us and we will promptly assist you. It's your responsibility to ensure that your account details are up to date. 
                </Text>

                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                    Most of the details you provide are displayed on your profile and visible to other users. The purpose of this is to help facilitate connection. Last name, email address and phone number are not displayed on your profile or visible to other users.
                </Text>
            </Box>

            <Box paddingLeft={paddingHorizontal} paddingRight={paddingHorizontal} paddingTop={boxPaddingTop}>
                <Text fontSize={subtitleFontSize} textColor={textColor} fontWeight="bold">
                    Device and Internet Information:
                </Text>
                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                    When you use the App, we might gather data related to your device. This could include its unique device ID, IP address, advertisement ID, the model of the device, its operating system, and the MAC address, all in accordance with this policy. Moreover, with your permission, the App accesses your device's contact list. The purpose of this is safety (i.e., to provide you with a view into the number of mutual contacts you share with other users) and fraud/bot prevention.
                </Text>
            </Box>

            <Box paddingLeft={paddingHorizontal} paddingRight={paddingHorizontal} paddingTop={boxPaddingTop}>
                <Text fontSize={subtitleFontSize} textColor={textColor} fontWeight="bold">
                    Purchase Information:
                </Text>
                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                    If you decide to purchase any of our premium services, we will process your payment information through our third-party payment processors (Apple, Stripe), and we will retain records relating to your purchase history.
                </Text>
            </Box>

            <Box paddingLeft={paddingHorizontal} paddingRight={paddingHorizontal} paddingTop={boxPaddingTop}>
                <Text fontSize={subtitleFontSize} textColor={textColor} fontWeight="bold">
                    Interactions Information:
                </Text>
                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                    We collect information related to the interactions between users on the App. This includes information in the App related to Likes, Matches, Messages, Ghosts and Reports. We use this information to improve the app for our users.  
                </Text>

                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                    Additionally, we collect information related to the interactions between you and our Customer Service team. This includes email address, IP address, as well as the information you send to us to help resolve your query.
                </Text>
            </Box>

            <Box paddingLeft={paddingHorizontal} paddingRight={paddingHorizontal} paddingTop={boxPaddingTop}>
                <Text fontSize={subtitleFontSize} textColor={textColor} fontWeight="bold">
                    Cookies and Similar Technologies:
                </Text>
                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                    When you use our App, we may collect personal information from you automatically by using cookies or similar technologies. A cookie is a small file that can be placed on your device or browser that allows us to recognize and remember you.
                </Text>
            </Box>

            <Box paddingLeft={paddingHorizontal} paddingRight={paddingHorizontal} paddingTop={boxPaddingTop}>
                <Text fontSize={subtitleFontSize} textColor={textColor} fontWeight="bold">
                    Inferred Information:
                </Text>
                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                    We may infer certain information about you based on the information we collect.
                </Text>
            </Box>


            {/* 2. How We Use Your Information */}


            <Box paddingLeft={paddingHorizontal} paddingRight={paddingHorizontal} paddingTop={boxPaddingTop}>
                <Text fontSize={titleFontSize} textColor={textColor} fontWeight="bold">
                    2. How We Use Your Information
                </Text>
            </Box>

            <Box paddingLeft={paddingHorizontal} paddingRight={paddingHorizontal} paddingTop={boxPaddingTop}>
                <Text fontSize={subtitleFontSize} textColor={textColor} fontWeight="bold">
                    Service Provision:
                </Text>
                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                    Your personal details aid us in offering our services, specifically to: 
                </Text>
                <UnorderedList paddingTop={paddingTop}>
                    <ListItem>Operate, maintain, and enhance the App and our operations;</ListItem>
                    <ListItem>Establish, safeguard, and confirm your account's authenticity;</ListItem>
                    <ListItem>Process transactions and manage payments with you via third-party providers;</ListItem>
                    <ListItem>Engage with you, which includes sending out updates, notifications, and other messages;</ListItem>
                    <ListItem>Cater to your preferences and interests, and customize your interactions with the App and our communications;</ListItem>
                    <ListItem>Test new technologies and processes designed to enhance and improve the App;</ListItem>
                    <ListItem>Resolve disputes, troubleshoot problems and to enforce our Terms &amp; Conditions;</ListItem>
                    <ListItem>Investigate fraud, protect our legal rights, and to enforce our Terms &amp; Conditions;</ListItem>
                    <ListItem>Offer support related to the App and address your inquiries and feedback.</ListItem>
                </UnorderedList>
            </Box>

            <Box paddingLeft={paddingHorizontal} paddingRight={paddingHorizontal} paddingTop={boxPaddingTop}>
                <Text fontSize={subtitleFontSize} textColor={textColor} fontWeight="bold">
                    Innovation and Analysis:
                </Text>
                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                    We might convert personal data into anonymous form and use this anonymized data for R&amp;D purposes, such as evaluating and advancing the App and expanding our enterprise.
                </Text>
            </Box>

            <Box paddingLeft={paddingHorizontal} paddingRight={paddingHorizontal} paddingTop={boxPaddingTop}>
                <Text fontSize={subtitleFontSize} textColor={textColor} fontWeight="bold">
                    Promotional and Advertising Activities:
                </Text>
                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                    For promotional and advertisement reasons, both we and our promotional associates might use your personal details to send you promotional communications as legally permitted. This might include in-app alerts, emails and texts.
                </Text>
            </Box>

            <Box paddingLeft={paddingHorizontal} paddingRight={paddingHorizontal} paddingTop={boxPaddingTop}>
                <Text fontSize={subtitleFontSize} textColor={textColor} fontWeight="bold">
                    Compliance and Safeguarding:
                </Text>
                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                    Your personal data might be used to:
                </Text>

                <UnorderedList paddingTop={paddingTop}>
                    <ListItem>Adhere to laws, respond to lawful demands and processes, such as complying with government authority requests;</ListItem>
                    <ListItem>Safeguard and defend our rights, your rights, and the rights of others, which includes maintaining privacy, safety, and making as well as defending legal accusations;</ListItem>
                    <ListItem>Review our internal adherence to legal and contractual obligations and company policies;</ListItem>
                    <ListItem>Uphold the governing terms and conditions of the App; and </ListItem>
                    <ListItem>Counteract, detect, probe, and prevent activities that might be harmful, unauthorized, unethical, or illegal, like cyber threats and identity theft.</ListItem>
                </UnorderedList>
            </Box>

            <Box paddingLeft={paddingHorizontal} paddingRight={paddingHorizontal} paddingTop={boxPaddingTop}>
                <Text fontSize={subtitleFontSize} textColor={textColor} fontWeight="bold">
                    Lawful Basis:
                </Text>
                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                    Under EU and UK data protection laws, we are required to tell you our lawful basis for using your data and we have set this out in the table below. Where the legal basis is consent, you can withdraw consent at any time. Where the legal basis is legitimate interests, you have a right to object to our use of your data. We explain in the relevant sections in this Policy how you can withdraw consent or opt-out of certain data uses (where applicable).
                </Text>
            </Box>

            <Box paddingLeft={paddingHorizontal} paddingRight={paddingHorizontal} paddingTop={boxPaddingTop}>
                <TableContainer>
                    <Table variant='normal'>
                        <Thead>
                            <Tr>
                                <Th>Purpose for which data is used</Th>
                                <Th>Data</Th>
                                <Th>Source</Th>
                                <Th>Legal basis</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td width="25%" whiteSpace="normal">
                                    To provide you with Surf’s social networking service
                                </Td>
                                <Td width="25%" whiteSpace="normal">
                                    Name, email address, phone number, height, date of birth, gender identity, sexual orientation, city of residence, hometown, education, activities, profession, exercise, languages, interests, ethnicity, religion, politics, kids, drinking, photos
                                </Td>
                                <Td width="25%" whiteSpace="normal">
                                    You provide this data to us
                                </Td>
                                <Td width="25%" whiteSpace="normal">
                                    Contractual necessity
                                </Td>
                            </Tr>
                            <Tr>
                                <Td width="25%" whiteSpace="normal">
                                    To enhance safety by providing you with a view into the number of mutual contacts you share with other users, and to help prevent fraud/bots.
                                </Td>
                                <Td width="25%" whiteSpace="normal">
                                    Phone contact list
                                </Td>
                                <Td width="25%" whiteSpace="normal">
                                    You give us permission to access this data
                                </Td>
                                <Td width="25%" whiteSpace="normal">
                                    Contractual necessity
                                </Td>
                            </Tr>
                            <Tr>
                                <Td width="25%" whiteSpace="normal">
                                    To take payment for premium services (not applicable for Users making payments via the App Store)
                                </Td>
                                <Td width="25%" whiteSpace="normal">
                                    Payment card details
                                </Td>
                                <Td width="25%" whiteSpace="normal">
                                    You provide this data to us
                                </Td>
                                <Td width="25%" whiteSpace="normal">
                                    Contractual necessity
                                </Td>
                            </Tr>
                            <Tr>
                                <Td width="25%" whiteSpace="normal">
                                    To block payment transactions as part of our anti-fraud procedures
                                </Td>
                                <Td width="25%" whiteSpace="normal">
                                    Name, IP address, email address, mobile number, cardholder name, payments received, type of payment, user ID, country
                                </Td>
                                <Td width="25%" whiteSpace="normal">
                                    You provide your name, email address, mobile number, and card details to us. We obtain your IP address from the device that you use to contact us. We obtain your payment information from your interactions with our service
                                </Td>
                                <Td width="25%" whiteSpace="normal">
                                    Legitimate interests – it is in our legitimate interests to prevent fraudulent transactions and to maintain the security of our services
                                </Td>
                            </Tr>
                            <Tr>
                                <Td width="25%" whiteSpace="normal">
                                    To carry out research and analysis to help us improve the App
                                </Td>
                                <Td width="25%" whiteSpace="normal">
                                    Log and usage data, including IP address, browser type, referring domain, pages accessed, mobile carrier and search terms, images and video
                                </Td>
                                <Td width="25%" whiteSpace="normal">
                                    You provide photos and videos to us. We obtain the log and usage information from the device that you use to access the App
                                </Td>
                                <Td width="25%" whiteSpace="normal">
                                    Legitimate interests – it is in our interests to analyze the way in which Users are accessing and using our services so that we can further develop the App, implement security measures and improve the service
                                </Td>
                            </Tr>
                            <Tr>
                                <Td width="25%" whiteSpace="normal">
                                    To respond to correspondence and queries that you submit to us, including social media queries
                                </Td>
                                <Td width="25%" whiteSpace="normal">
                                    Email address and IP address, social media name, phone number
                                </Td>
                                <Td width="25%" whiteSpace="normal">
                                    You provide your email address, social media name, and phone number to us when you contact us and we obtain your IP address from the device that you use to contact us
                                </Td>
                                <Td width="25%" whiteSpace="normal">
                                    Legitimate interests – it is in our legitimate interests to respond to your queries to ensure that we provide a good service to Users and troubleshoot problems
                                </Td>
                            </Tr>
                            <Tr>
                                <Td width="25%" whiteSpace="normal">
                                    To investigate and block Users for reported infringements of our Terms of Service
                                </Td>
                                <Td width="25%" whiteSpace="normal">
                                    Name and user registration details, profile information, content of messages and photographs
                                </Td>
                                <Td width="25%" whiteSpace="normal">
                                    You provide your name, profile content, and activities on the App to us
                                </Td>
                                <Td width="25%" whiteSpace="normal">
                                    Legitimate interests – it is in our legitimate interests to prevent unauthorized behavior and to maintain the safety and integrity of our services
                                </Td>
                            </Tr>
                            <Tr>
                                <Td width="25%" whiteSpace="normal">
                                    To contact you in order to run surveys for research purposes and to obtain feedback, and to find out if you want to take part in campaigns
                                </Td>
                                <Td width="25%" whiteSpace="normal">
                                    Email address and mobile phone number
                                </Td>
                                <Td width="25%" whiteSpace="normal">
                                    You provide this information to us
                                </Td>
                                <Td width="25%" whiteSpace="normal">
                                    Legitimate interests – it is in our legitimate interests to carry out research so that we can further develop the app and improve the service
                                </Td>
                            </Tr>
                            <Tr>
                                <Td width="25%" whiteSpace="normal">
                                    To defend legal claims, protect legal rights, and to protect people from harm
                                </Td>
                                <Td width="25%" whiteSpace="normal">
                                    This could include any information that is relevant to the issue
                                </Td>
                                <Td width="25%" whiteSpace="normal">
                                    This information may be obtained directly from you, from your device, or from third parties, depending on the information involved
                                </Td>
                                <Td width="25%" whiteSpace="normal">
                                    Legitimate interests – it is in our legitimate interests to protect our legal rights, defend legal claims, and protect our users and third parties from harm
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>


            {/* 3. Disclosure of Information: */}


            <Box paddingLeft={paddingHorizontal} paddingRight={paddingHorizontal} paddingTop={boxPaddingTop}>
                <Text fontSize={titleFontSize} textColor={textColor} fontWeight="bold">
                    3. Disclosure of Information:
                </Text>    
                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                    Surf does not sell and has never sold your personal information. Our policy is to not disclose your Registration Information or personal information, except in limited circumstances described here:
                </Text>
            </Box>
            
            <Box paddingLeft={paddingHorizontal} paddingRight={paddingHorizontal} paddingTop={boxPaddingTop}>
                <TableContainer>
                    <Table variant='normal'>
                        <Thead>
                            <Tr>
                                <Th>Circumstances where data may be disclosed</Th>
                                <Th>Disclosed data</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td width="50%" whiteSpace="normal">
                                    Service Providers – We engage certain trusted third parties to perform functions and provide services to us. We may share your Registration Information or personal information with these third parties, but only for the purposes of performing these functions and providing such services. More information about this is available directly below.
                                </Td>
                                <Td whiteSpace="normal">
                                    This could include all information listed above
                                </Td>
                            </Tr>
                            <Tr>
                                <Td whiteSpace="normal">
                                    Payment Processing and Telecommunications Companies – To facilitate payments for our premium services.
                                </Td>
                                <Td whiteSpace="normal">
                                    Cardholder name, cardholder address, card number, payment amount, transaction date/time
                                </Td>
                            </Tr>
                            <Tr>
                                <Td whiteSpace="normal">
                                    Law and Harm – As we mentioned in the Terms of Service, we think it is very important that all Users behave while using the App. We will cooperate with all third parties to enforce their intellectual property or other rights. We will also cooperate with law enforcement inquiries from within or outside your country of residence where we are required to by law, where there is an investigation into alleged criminal behavior or to protect the vital interests of a person. This may include preserving or disclosing any of your information, including your Registration Information if we believe in good faith that it is necessary to comply with a law or regulation, or when we believe that disclosure is necessary to comply with a judicial proceeding, court order, or legal request; to protect the safety of any person; to address fraud, security or technical issues e.g. through anti-spam providers to protect the service from criminal activity or to protect our rights or property or those of third parties. In such cases, we may raise or waive any legal objection or right available to us.
                                </Td>
                                <Td whiteSpace="normal">
                                    This could include any personal information that Surf holds about you
                                </Td>
                            </Tr>
                            <Tr>
                                <Td whiteSpace="normal">
                                    Business Transfers – In the event that Surf or any of its affiliates undergoes a business transition or change of ownership, such as a merger, acquisition by another company, re-organization, or sale of all or a portion of its assets, or in the event of insolvency or administration, we may be required to disclose your personal information.
                                </Td>
                                <Td whiteSpace="normal">
                                    This could include all personal information that Surf holds about you
                                </Td>
                            </Tr>
                            <Tr>
                                <Td whiteSpace="normal">
                                    Anti-Spam and Anti-Fraud – Your data may be shared with other Surf verified partners, for example, to block accounts and suspected fraudulent payment transactions as part of our anti-spam and anti-fraud procedures.
                                </Td>
                                <Td whiteSpace="normal">
                                    Email address, phone number, IP address, and IP session information, social network ID, username, user agent string, and transaction and payment data
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>


            <Box paddingLeft={paddingHorizontal} paddingRight={paddingHorizontal} paddingTop={boxPaddingTop}>
                <Text fontSize={subtitleFontSize} textColor={textColor} fontWeight="bold">
                    Aggregated Information:
                </Text>
                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                    We may share aggregated information with third parties that includes your personal information (but which doesn&rsquo;t identify you directly) together with other information including log data for industry analysis and demographic profiling.
                </Text>
            </Box>

            <Box paddingLeft={paddingHorizontal} paddingRight={paddingHorizontal} paddingTop={boxPaddingTop}>
                <Text fontSize={subtitleFontSize} textColor={textColor} fontWeight="bold">
                    More information about disclosures:
                </Text>
                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                    We collaborate with certain reputable third-party organizations to execute specific tasks and offer services on our behalf (&quot;Service Providers&quot;). The partners with whom we share user personal data can vary based on factors such as user interactions with our App and services. For instance, to deliver our services to users, we typically engage the following partners: 
                </Text>

                <UnorderedList paddingTop={paddingTop}>
                    <ListItem>Payment facilitators &ndash; like Apple and Stripe</ListItem>
                    <ListItem>Identity verification services &ndash; to help customers confirm their account and provide other users with greater assurance regarding the user's identity</ListItem>
                    <ListItem>Product enhancement and market analysis &ndash; we employ external platforms and firms to conduct user surveys and market studies to refine our offerings</ListItem>
                    <ListItem>IT support &ndash; some third-party software entities that support our operations might process users' personal data.</ListItem>
                </UnorderedList>
            </Box>


            {/* 4. What Others May See About You */}


            <Box paddingLeft={paddingHorizontal} paddingRight={paddingHorizontal} paddingTop={boxPaddingTop}>
                <Text fontSize={subtitleFontSize} textColor={textColor} fontWeight="bold">
                    4. What Others May See About You
                </Text>
                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                    While using Surf, assume that any content you post or submit might be visible and available to the public, including both App users and non-users. As such, we advise users to exercise caution when sharing information that could be made public.
                </Text>
                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                    Be prudent about sharing personal details on your profile, such as your phone number or email address. Understand that images or videos you share can also disclose personal information. By choosing to share sensitive details on your profile, you're giving explicit consent for us to handle this data and make it accessible to other users.
                </Text>
                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                    The content you provide on the App can be viewed and shared by users with others, who might not be app users. For instance, a user might share a screenshot of your profile image with their Facebook friends, irrespective of whether those friends use the App. 
                </Text>
                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                    Be aware that users can view past matches and access their chat histories. We cannot control how users might share or use this data.    
                </Text>
            </Box>


            {/* 5. Age 18 or Older  */}


            <Box paddingLeft={paddingHorizontal} paddingRight={paddingHorizontal} paddingTop={boxPaddingTop}>
                <Text fontSize={subtitleFontSize} textColor={textColor} fontWeight="bold">
                    5. Age 18 or Older 
                </Text>
                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                    To use Surf, you must be at least 18 years old.
                </Text>
                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                    If you're younger than 18, we kindly ask that you refrain from providing us with any information. Should a profile be removed due to a violation of our age policies, we might keep hold of the associated information to prevent any attempts to bypass our guidelines by setting up a new account.
                </Text>
            </Box>

            {/* 6. Security */}

            <Box paddingLeft={paddingHorizontal} paddingRight={paddingHorizontal} paddingTop={boxPaddingTop}>
                <Text fontSize={subtitleFontSize} textColor={textColor} fontWeight="bold">
                    6. Security 
                </Text>
                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                    We put in considerable effort to implement security measures to protect your data from loss, misuse, unauthorized access, or disclosure. We deploy reliable security methods, including secure servers and firewalls, to keep your personal information confidential.
                </Text>

                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                    However, it's essential to understand that no online platform or data transmission can be guaranteed to be 100% secure. While we aim to protect your data, we cannot ensure that there won't be unauthorized access, hacking, data loss, or other security breaches. Here are some suggestions to enhance the security of your data:
                </Text>

                <UnorderedList paddingTop={paddingTop}>
                    <ListItem>Always log out of your account, particularly when using shared devices.</ListItem>
                    <ListItem>Avoid sharing the password linked to your account.</ListItem>
                    <ListItem>Regularly update your password</ListItem>
                </UnorderedList>
                
                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                    If you suspect any unauthorized access to your password or account, please contact us immediately. Remember, transmitting personal information to the App carries its risks, and we urge you to be cautious.
                </Text>
            </Box>

            {/* 7. Your California Privacy Rights  */}

            <Box paddingLeft={paddingHorizontal} paddingRight={paddingHorizontal} paddingTop={boxPaddingTop}>
                <Text fontSize={subtitleFontSize} textColor={textColor} fontWeight="bold">
                    7. Your California Privacy Rights 
                </Text>
                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                    For those residing in California, aside from the rights outlined in section 8, the California Consumer Privacy Act grants you these additional privileges, ensuring that there's no unlawful bias against you for exercising them: 
                </Text>

                <UnorderedList paddingTop={paddingTop}>
                    <ListItem>The right to ask us to reveal and clarify how we've gathered, utilized, and disseminated your personal data in the past year.</ListItem>
                    <ListItem>The right to ask for the deletion of your personal data that we've acquired, although some exceptions apply.</ListItem>
                    <ListItem>The right to opt out from the selling of personal data.</ListItem>
                </UnorderedList>
                
                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                The California &quot;Shine the Light&quot; provision (Civil Code section 1798.83) mandates specific businesses to address inquiries from Californian clients regarding their practices of disclosing personal information to third parties for those third parties' direct marketing endeavors. If you'd like to know more about any potential rights under the California Civil Code section 1798.83, please contact us.
                </Text>

                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                Moreover, California regulations mandate online service providers to reveal their responses to &quot;do not track&quot; signals or similar consumer choice mechanisms regarding the accumulation of personal data across different online services over time. Currently, we don&rsquo;t monitor our users' personal information in this manner. The law also obliges these providers to disclose if third parties can gather user information across various online platforms. As of now, we're not aware of third parties collecting personal data from our users across different online platforms when utilizing the App.
                </Text>
            </Box>

            {/* 8. Your Rights */}

            <Box paddingLeft={paddingHorizontal} paddingRight={paddingHorizontal} paddingTop={boxPaddingTop}>
                <Text fontSize={subtitleFontSize} textColor={textColor} fontWeight="bold">
                    8. Your Rights
                </Text>
                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                    Depending on the privacy laws of your country, you might be entitled to the following rights: 
                </Text>    

                <UnorderedList paddingTop={paddingTop}>
                    <ListItem>Information Right: This grants you insight into what personal data an entity is processing and for what reasons (this is detailed in our Privacy Policy).</ListItem>
                    <ListItem>Access Right: This allows you to obtain a copy of the data we have on you.</ListItem>
                    <ListItem>Rectification Right: If there's an error in the data we hold about you, you can ask for corrections.</ListItem>
                    <ListItem>Erasure Right: Under certain conditions, you're entitled to request that we erase your data.</ListItem>
                    <ListItem>Processing Restriction Right: In specific scenarios, you can request that processing is stopped but the information retained.</ListItem>
                    <ListItem>Data Portability Right: You can ask for certain data in a format that's easily transferrable to another service provider.</ListItem>
                    <ListItem>Objection Right: Under certain conditions, especially when data is processed based on legitimate interests or marketing purposes, you can object to our processing of your data.</ListItem>
                </UnorderedList>

                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                    If you wish to exercise any of your rights listed above, or if you have a concern about how we processed your request or your personal information, please contact us.
                </Text>
            </Box>

            {/* 9. Data Processing in the United States  */}

            <Box paddingLeft={paddingHorizontal} paddingRight={paddingHorizontal} paddingTop={boxPaddingTop}>
                <Text fontSize={subtitleFontSize} textColor={textColor} fontWeight="bold">
                    9. Data Processing in the United States 
                </Text>
                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                    Surf is headquartered in the United States, and we might collaborate with service providers based in different countries. As a result, your personal data might be managed in the United States or be sent to regions where privacy regulations may not be as stringent as those in your local area, state, or nation.
                </Text>
            </Box>

            {/* 10. Data Retention and Deletion */}

            <Box paddingLeft={paddingHorizontal} paddingRight={paddingHorizontal} paddingTop={boxPaddingTop}>
                <Text fontSize={subtitleFontSize} textColor={textColor} fontWeight="bold">
                    10. Data Retention and Deletion
                </Text>
                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                    We retain your personal data only as long as necessary for the purposes outlined (as mentioned in Section 2) and in compliance with relevant laws. 
                </Text>    
                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                    You can opt to delete your account at any point, but we advise first considering deactivation since once deleted, it cannot be undone. Once your account is deactivated, we make genuine efforts to ensure it's not visible to App users. Within 28 days of deactivation, there's a possibility to reactivate your Account if it was unintentionally or mistakenly deactivated. Beyond this period, we can't assure the recovery of your data, except under certain scenarios: 
                </Text>    

                <UnorderedList paddingTop={paddingTop}>
                    <ListItem>Compliance with legal requirements (e.g., for accounting purposes if purchases were made within the App).</ListItem>
                    <ListItem>As proof of our adherence to laws.</ListItem>
                    <ListItem>Pending issues, claims, or disputes that necessitate retaining the information until settled.</ListItem>
                    <ListItem>Requirements for our valid business purposes, such as safeguarding users or fraud prevention. For instance, to prevent a banned user due to security concerns from creating a new account.</ListItem>
                </UnorderedList>
                    
                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                    To avert potential misuse of Surf by a user following termination or deletion of a profile/account, we'll hold onto data as we deem in our sole discretion may be necessary to ensure that user does not open a new account and profile in breach of our Terms of Service and to ensure compliance with all regulations and laws. 
                </Text>

                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>    
                    Note: Removing data from your profile or erasing your account doesn't ensure the data's complete removal if it was previously shared, stored, or copied by others. We cannot oversee this and aren't liable for it. If you have given third-party applications or websites access to your personal information, they might keep it according to their terms of service or privacy policies.
                </Text>
            </Box>

            {/* 11. Changes to This Policy  */}

            <Box paddingLeft={paddingHorizontal} paddingRight={paddingHorizontal} paddingTop={boxPaddingTop}>
                <Text fontSize={subtitleFontSize} textColor={textColor} fontWeight="bold">
                    11. Changes to This Policy 
                </Text>
                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                    As Surf evolves, we may revise this Privacy Policy from time to time. The most current version of the policy will govern our use of your information and will always be available on our website. If we make a change tot his policy that, in our sole discretion, is material, we will notify you, for example, via an email to the email associated with your account.
                </Text>
            </Box>

            {/* 12. Contact Us  */}

            <Box paddingLeft={paddingHorizontal} paddingRight={paddingHorizontal} paddingTop={boxPaddingTop}>
                <Text fontSize={subtitleFontSize} textColor={textColor} fontWeight="bold">
                    12. Contact Us 
                </Text>
                <Text fontSize={fontSize} paddingTop={paddingTop} textColor={textColor}>
                    You can contact us by visiting this page on our website: https://www.surfdatingapp.com/contact-us
                </Text>
            </Box>
        </>
    )
}