import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import Main from './components/Main';
import './App.css';

const theme = extendTheme({
    colors: {
        brand: {
            25: "white",
            50: "white",
            100: "white",
            500: "white",
            600: "white",
        }
    },
    styles: {
        global: () => ({
            body: {
                fontFamily: "Lato"
            }
        })
    }
})

function App() {
    return (
        <ChakraProvider theme={theme}>
            <Main />
        </ChakraProvider>
    );
}

export default App;
