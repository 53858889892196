import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { LandingPage } from './Landing/LandingPage';
import { PrivacyPolicyPage } from './Legal/PrivacyPolicyPage';

const Main = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={ <LandingPage /> } />
                <Route path='/privacy-policy' element={ <PrivacyPolicyPage /> } />
            </Routes>
        </BrowserRouter> 
    )
}
export default Main