import axios from "axios"
import { useState } from "react"
import { Helmet } from "react-helmet"
import {
    Button,
    Center,
    HStack,
    Image,
    Input,
    Stack,
    Text,
    useToast,
    VStack,
} from "@chakra-ui/react"

import { useDevice } from "../../hooks/useDeviceContext"

export const LandingPage = () => {
    const { isMobile } = useDevice()
    const toast = useToast()

    const fontSize = isMobile ? "20px" : "30px"
    const [email, setEmail] = useState("")

    const validateEmail = (email: string) => {
        return String(email).toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
    }

    const sendRequest = async () => {
        if (validateEmail(email)) {
            console.log(process.env.REACT_APP_API_URL)
            const res = await axios.post(`${process.env.REACT_APP_API_URL}signup/create`, { email })
            if (res.data.success) {
                toast({
                    title: 'Done!',
                    description: "We've added your email!",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })
                setEmail("")
            } else {
                toast({
                    title: 'Looks like this email has already been added',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            }
        } else {
            toast({
                title: "Looks like this isn't a valid email",
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }
    }

    return (
        <>
            <Helmet>
                <title>Surf</title>
                <meta name="description" content="The Searchable Dating App." />
            </Helmet>
            <Center
                bgGradient='linear(to-br, #D4C5FC, #F9BFF7)'
                h={"100vh"}
                w={"100vw"}
                overflow="hidden"
            >
                <Stack
                    ml={isMobile ? 10 : 20}
                    mr={isMobile ? 10 : 20}
                >
                    <Center
                        fontSize={fontSize}
                        textAlign="center"
                        textColor="white"
                    >
                        LAUNCHING IN WASHINGTON D.C.
                    </Center>
                    {/* <Center
                        fontSize={fontSize}
                        textAlign="center"
                        textColor="white"
                    >
                        OCTOBER 2022
                    </Center> */}
                    <Center>
                        <Image src="https://siift-images.s3.amazonaws.com/logo.png" h={"80px"} w={"80px"} m={10} />
                    </Center>
                    <Center
                        fontSize={fontSize}
                        textAlign="center"
                        textColor="white"
                    >
                        {"Stop Swiping,"}
                        <Text 
                            color="black"
                            fontSize={fontSize}
                            ml={2}
                            fontWeight={1000}
                        >
                            {"Start Surfing"}
                        </Text>
                    </Center>
                    {/* {
                        isMobile ? (
                            <VStack pt={10} spacing={10}>
                                <Input
                                    pl="10px"
                                    pr="10px"
                                    pt="30px"
                                    pb="30px"
                                    borderRadius="5px"
                                    color="black"
                                    w="100%"
                                    variant="outline"
                                    bg="white"
                                    value={email}
                                    placeholder="Please enter Email"
                                    fontSize={20}
                                    onChange={ (event) => setEmail(event.target.value) }
                                /> 
                                <Button
                                    colorScheme="brand"
                                    size="md"
                                    variant="solid"
                                    pl={8}
                                    pr={8}
                                    pt="30px"
                                    pb="30px"
                                    fontSize={20}
                                    color="black"
                                    width="200px"
                                    onClick={sendRequest}
                                >
                                    Stay In The Know
                                </Button>
                            </VStack>
                        ) : (
                            <HStack pt={20} spacing={10}>
                                <Input
                                    pl="10px"
                                    pr="10px"
                                    pt="30px"
                                    pb="30px"
                                    borderRadius="5px"
                                    w="100%"
                                    variant="outline"
                                    value={email}
                                    bg="white"
                                    placeholder="Please enter Email"
                                    fontSize={20}
                                    onChange={ (event) => setEmail(event.target.value) }
                                /> 
                                <Button
                                    colorScheme="brand"
                                    size="md"
                                    variant="solid"
                                    pl={8}
                                    pr={8}
                                    pt="30px"
                                    pb="30px"
                                    fontSize={20}
                                    color="black"
                                    width="300px"
                                    onClick={sendRequest}
                                >
                                    Stay In The Know
                                </Button>
                            </HStack>
                        )
                    } */}
                </Stack>
            </Center>
        </>
    )
}